.App {
    text-align: center;
}

.App-title {
    border-left: solid 2px #DC0812;
    text-align: left;
    padding-left: 20px;
}

p {
    text-align: justify;
    font-size: large;
    margin-top: 40px;
}

b {
    font-weight: bold;
}

a, a:visited{
    color: #DC0812;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

h2 {
    width: 80%;
    margin-left: 10%;
    text-align: center;

    b {
        color: #DC0812;
    }
}

iframe {
    position: fixed;
    top: 25%;
    right: 20px;
}

.article-left {
    border-left: solid 2px #DC0812;
    padding-left: 20px;
}

.article-right {
    border-right: solid 2px #DC0812;
    padding-right: 20px;
}

.origins {
    color: #fff;
    text-shadow:
        0 0 4px #fff,
        0 0 11px #fff,
        0 0 19px #fff,
        0 0 40px #D4AF37,
        0 0 80px #D4AF37,
        0 0 90px #D4AF37,
        0 0 100px #D4AF37,
        0 0 150px #D4AF37;
}

.discord {
    width: 100vw;
    height: 15vw;
    margin-left: -20vw;
    background-color: #211E1B;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .discord-logo {
        height: 70%;
        margin-left: 18vw;
    }

    .content {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        div {
            .message {
                font-size: 2em;
                margin-bottom: 0;
            }

            .discord-join {
                display: flex;
                flex-direction: row;
                align-items: center;

                .button {
                    margin-right: 20px;
                }

                .dot {
                    height: 10px;
                    width: 10px;
                    background-color: #bbb;
                    border-radius: 50%;
                    display: inline-block;
                    background-color: #23A737;
                }
            }
        }
    }

    .banner {
        position: relative;
        width: 15vw;
        right: 20vw;
        bottom: -58px;
    }
}

.button {
    width: 13vw;
    height: 3vw;
    background-image: url("/images/svg/join_btn.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px;
    text-align: center;
    line-height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    text-transform: uppercase;
    color: white;
    font-family: "MontSerratBold";
    text-decoration: none;
  }

#tsparticles {
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    z-index: -1;
}


@media screen and (max-width: 500px) {
    header, main, footer {
        width: 90vw;
        height: auto;
        margin: auto;
    }

    h1 {
        font-size: large;
    }

    h2 {
        font-size: medium;
    }

    p {
        font-size: small;
    }
}

@media screen and (max-width: 1000px) {
    iframe {
        position: absolute;
    }
}